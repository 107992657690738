@import './mixins.scss';
@import './variables.scss';

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px 12px 24px;
  min-height: 60px;
  gap: 15px;
}

.ordersContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.order {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px 0px #00000040;
  border-radius: 5px;
  
  div {
    font-weight: 400;
  }

  .num {
    font-size: 18px;
    line-height: 44px;
    width: 100%;
  }

  .status {
    display: flex;
    flex-basis: 30%;
  }
}

.orderBtn {
  justify-self: center;
  display: flex;
  margin-right: 30px;
  justify-content: flex-end;
  align-items: center;
  
  div {
    height: 10px;
  }
}

.item {
  padding: 10px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  h3 {
    font-size: 24px;
    line-height: 44px;
    font-weight: 400;
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}