@import '../../styles/variablesNew.scss';

.tableContainer {
  overflow-x: auto;
  
  table {
    width: 100%;
  }
}

.noProducts {
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    margin-top: 20px;
  }
}

.productTable {
  th, td {
    padding: 10px 10px 0 0;
    text-align: left;
    @media screen and (max-width: $tablet-width) {
      padding: 10px 4*10px 10px 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &:first-child {
      padding-right: 20px;
    }
  }
  th {
    font-size: 18px;
    line-height: 120%;
    font-weight: 500;
  }
  td {
    button {
      white-space: nowrap;
    }
  }
}
.noWrap {
  white-space: nowrap;
}

.cartCountControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  span {
    display: inline-block;
    text-align: center;
    font-weight: 500;
    margin: 0 auto;
  }
  button {
    display: inline-block;
    font-size: 20px;
    padding-top: 2px;
    height: 30px;
    color: darken($primary, 50%);
  }
}

.skeletonTable {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 0.5fr;
  column-gap: 10px;
  row-gap: 25px;
  .skeletonHeader {
    height: 30px;
    width: 100px;
  }
  .skeletonItem {
    height: 20px;
  }
}

.title {
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: $primary;
  }

}

.modal {
  max-width: initial;
  width: auto;
}

.countInput {
  margin: 0 5px;
  max-width: 30px;
}

.product {
  margin-bottom: 10px;
  padding-bottom: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
}

.infoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}