@import 'styles/variablesNew';

.requestsContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.requestCard {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 12px 24px;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px #00000040;
  @media screen and (max-width: $tablet-width) {
    flex-direction: column;
  }
}

.requestNum {
  font-weight: 400;
  font-size: 18px;
}

.requestBtn {
  div {
    height: 10px;
    font-size: 14px;
  }
}

.activeStatus {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1F9741;
}