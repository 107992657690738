@import '../../styles/variablesNew.scss';

.outlined {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid $primary;
  background-color: white;
  color: $primary;
  font-family: 'Noto sans';
  font-size: 16px;
  cursor: pointer;
  width: fit-content;

  &:hover:not(:disabled) {
    border-color: #1F4CEB;
    background-color: #f6f9ff;
  }

  &:active:not(:disabled) {
    box-shadow: 0px 4px 8px 0px #0000001A inset;
  }

  &:disabled {
    color: #909090;
    cursor: auto;
    border: 1px solid #B9B9B9;
  }
}

.outlined-mini {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 8px 20px 8px 20px;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #DEDEDE;
  background-color: white;
  color: black;
  font-family: 'Noto sans';
  font-size: 16px;
  cursor: pointer;
  width: fit-content;

  &:hover:not(:disabled) {
    border-color: #1F4CEB;
    background-color: #f6f9ff;
  }

  &:active:not(:disabled) {
    box-shadow: 0px 4px 8px 0px #0000001A inset;
  }

  &:disabled {
    color: #909090;
    border: 1px solid #B9B9B9;
    cursor: auto;
  }
}

.filled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  border-radius: 8px;
  background-color: $primary;
  color: white;
  border: 0;
  font-family: 'Noto sans';
  font-size: 16px;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: #0043DD;
    color: white;
  }
  
  &:active:not(:disabled) {
    box-shadow: 0px 4px 4px 0px #00000040 inset;
  }

  &:disabled {
    background-color: #F2F2F2;
    border: none;
    color: #A1A1A1;
    cursor: auto;
  }
}

.secondary {
  display: flex;
  float: right;
  flex-direction: row;
  align-items: center;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  height: 44px;
  background-color: $secondary;
  border: 0;
  gap: 10px;
  text-align: center;
  // transition: all 0.5s ease;
  overflow: hidden;
  font-family: 'Noto sans';
  font-size: 16px;
  cursor: pointer;

  @media screen and (max-width: $desktop-width) {
    padding: 10px 16px;
  }

  &:hover:not(:disabled) {
    background-color: #F7CD31;
  }

  &:active:not(:disabled) {
    box-shadow: 0px 4px 4px 0px #00000040 inset;
  }

  &:disabled {
    background-color: #F2F2F2;
    border: none;
    color: #A1A1A1;
    cursor: auto;
  }
}

.size_md {
  padding: 10px 32px;
  min-height: 44px;
}

.size_sm {
  padding: 2px 12px;
  min-height: 38px;
}