@import './mixins.scss';
@import './variablesNew.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  @include media('>#{$mobile-step}') {
    flex-direction: row;
  }
}

.stepContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include media('>#{$mobile-step}') {
    flex-direction: row;
    align-items: center;
  }
}

.step {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  & > div {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    @include media('>#{$mobile-step}') {
      width: 42px;
      height: 42px;
      font-size: 20px;
    }
  }
  & > p {
    margin: 0;
    font-size: 14px;
    @include media('>#{$mobile-step}') {
      font-size: 16px;
    }
  }
}
.stepPassed {
  & > div {
    background-color: $primaryLight;
    color: #000;
  }
  & > p {
    color: #000;
  }
}
.stepCurrent {
  & > div {
    background-color: $primary;
    color: #fff;
  }
  & > p {
    color: #000;
    font-weight: 600;
  }
}
.stepFutured {
  & > div {
    background-color: #EAEAEA;
    color: #6D6D6D;
  }
  & > p {
    color: #888;
  }
}

.line {
  @include media('<=#{$mobile-step}') {
    height: 20px;
    width: 1px;
    border-left: 1px dashed #888;
  }
  @include media('>#{$mobile-step}') {
    height: 1px;
    width: 30px;
    border-top: 1px dashed #888;
  }
}