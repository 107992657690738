@import '../../styles/variablesNew.scss';

$mobile-header: 600px;

.color_primary {
  color: $primary !important;
}

.color_secondary {
  color: $secondary !important;
}

.color_muted {
  color: $muted !important;
}

.root {
  line-height: 24px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media screen and (max-width: $desktop-width) {
    header {
      padding: 0px 20px;
    }
  }

  .container {
    max-width: $desktop-width;
    width: 100%;
    align-self: center;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.hr {
  position: absolute;
  width: 100%;
  left: 0;
  border-width: 1px 0 0 0;
  margin: 0;
  border-top: 1px solid #F0F0F0;
}

.mobileNav {
  @media screen and (min-width: $mobile-header) {
    display: none;
  }

  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  left: 0;
  z-index: 222;
  height: 70px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  box-shadow: 0px 0px 4px 0px #0000001A;
  justify-content: space-evenly;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #555556;
    text-align: center;
    font-size: 14px;
  }
}

.nav1 {
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > a {
    display: none;
  }

  @media screen and (max-width: $desktop-width) {
    padding: 10px 0px;
  }
  

  @media screen and (max-width: $mobile-header) {
    & > a {
      display: block;
    }
  }
  
  .city {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .pages {
    display: flex;
    gap: 28px;
    flex-direction: row;
    align-items: center;

    :hover {
      text-decoration: underline;
    }

    @media screen and (max-width: $tablet-width) {
      display: none;
    }
  }
}

.searchIcon {
  border: none;
  background-color: white;
  cursor: pointer;
  display: none;
}

.search {
  display: flex;
  align-items: center;

}

.showOnCollapse {
  display: grid;
  grid-template-columns: 1fr;
  // transition: all 0.5s ease;
}

@media screen and (max-width: $search-hiding) {
  .searchCollapsed {
    display: grid;
    grid-template-columns: 0fr;
    // transition: all 0.5s ease;
    input {
      padding: 0 !important;
    }
  }
  .search {
    display: grid;
    grid-template-columns: 0fr;
    // transition: all 0.5s ease;
    width: 0%;
    overflow: hidden;
    position: relative;
    align-items: center;

    button {
      position: absolute;
      display: inline;
      border: none;
      font-size: 20px;
      color: gray;
      background-color: inherit;
      right: 12px;
      cursor: pointer;
      padding: 0;
      display: flex;

    }
  }
  .searchExpanded {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
  .searchIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    p {
      color: #555556;
    }
  }
}

.nav2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;

  .lvl1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    
    @media screen and (max-width: $desktop-width) {
      gap: 12px;
    }

    #adaptiveSearchContainer {
      display: flex;
      @media screen and (min-width: $search-hiding) {
        display: none;
      }
    }
    
    #desktopSearchContainer {
      flex-grow: 1;
      position: relative;

      @media screen and (max-width: $search-hiding) {
        display: none;
      }
    }

    .headSpecBtn {
      margin-left: auto;

      @media screen and (max-width: $desktop-width) {
        margin-right: auto;
      }
    }

    li {
      display: grid;
      grid-template-columns: 1fr;
    }
    
    @media screen and (max-width: $mobile-header) {
      li:not(:nth-child(3)) {
        display: none !important;
      }
    }

    .showOnExpand {
      flex-grow: 1;
    }

    .hideOnExpand {
      display: grid;
      grid-template-columns: 0fr;
      // transition: all 0.5s ease;
      flex-grow: 0;
      overflow: hidden;
      padding: 0px;
      * {
        padding: 0px !important;
        width: 0px;
      }
    }

    .searchInput {
      border-radius: 8px;
      padding: 10px 12px;
      width: 100%;
      border: 0;
      background-color: #EEEDEB;
      height: 44px;
      outline: 0;

      &:focus {
        border: 1px solid $primary;
        background-color: white;
      }
    }

    .navBtn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      font-size: 14px;
      color: #555556;
      transition: all 0.3s ease;

      &:hover {
        filter: brightness(0.8);
      }

      .cartLink {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
        align-items: center;
        position: relative;
      }

      .cartLinkCountBadge {
        position: absolute;
        right: -5px;
        top: -30px;
        margin-left: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;
        background-color: crimson;
      }
    }
  }

  .lvl2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    text-wrap: nowrap;
    padding: 10px 0px;
    flex-wrap: wrap;

    & li:hover {
      text-decoration: underline;
    }
    
    & li:hover:last-child {
      text-decoration-color: $primary;
    }

    @media screen and (max-width: $desktop-width) {
      justify-content: flex-start;
    }

    @media screen and (max-width: $tablet-width) {
      overflow-x: auto;
      margin: 0px -20px;
      flex-wrap: nowrap;

      & li:first-child {
        margin-left: 20px;
      }

      & li:last-child {
        margin-right: 20px;
      }
    } 


  }
}