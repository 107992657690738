
.listSection {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    color: #262626;
  }
}

.emptyListText {
  font-size: 14px;
  color: #666;
}

.requestBtns {
  display: flex;
  flex-direction: row;
  gap: 22px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}